import logo from './logo.svg';
import { useState, useEffect } from 'react';
import './App.css';

function App() {
  // Load saved theme from localStorage or default to light mode
  const [theme, setTheme] = useState(localStorage.getItem('theme') || 'light');

  // Toggle theme between light and dark
  const toggleTheme = () => {
    const newTheme = theme === 'light' ? 'dark' : 'light';
    setTheme(newTheme);
    localStorage.setItem('theme', newTheme); // Save theme to localStorage
  };

  useEffect(() => {
    // Apply the current theme to the root element
    document.documentElement.setAttribute('data-theme', theme);
  }, [theme]);

  return (
    <div className="App">
      <header className="App-header">
        <button
          onClick={toggleTheme}
          style={{
            fontSize: '1.5rem',
            position: 'absolute',
            top: '10px',
            left: '10px'
          }}
        >
          {theme === 'light' ? '🌙' : '🌞'}
        </button>
        <p>
          Neil Martin
        </p>
        <div className="App-subheader">
          <p>
            coming soon
          </p>
        </div>
      </header>
    </div>
  );
}

export default App;
